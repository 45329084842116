// import BGImg from "../../assets/images/backgrounds/slider-1-1.png";
import BGImg from "../../assets/new-images/train.jpg";
import BGImg1 from "../../assets/new-images/harbor-bridge.jpg";
import BGImg2 from "../../assets/new-images/family-time.jpg";
import BGImg3 from "../../assets/new-images/slide-1.jpg";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
// import './newstyles.css';

// import { Pagination } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import React, { useRef, useState } from "react";

SwiperCore.use([Navigation]);

function Firstslider() {
  //     const sliderOptions = {
  //         navigation: {
  //             nextEl: '.slider-one__carousel__btn__left',
  //             prevEl: '.slider-one__carousel__btn__right',
  //     }
  // }

  return (
    <>
      <section className="slider-one">
        {/* <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper> */}
        <Swiper
          style={{
            "--swiper-navigation-color": "#01BBBB",
            "--swiper-pagination-color": "#01BBBB",
            "--swiper-pagination-bullet-inactive-color": "#fff",
          }}
          spaceBetween={30}
          centeredSlides={false}
          autoplay={{ delay: 15000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide className="item">
            <div className="slider-one__item">
              {/* <div className="slider-one__lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
              <div
                className="slider-one__image"
                style={{ backgroundImage: `url(${BGImg1})` }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-right">
                    <p
                      className="slider-one__tagline col-lg-6"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <i className="fa fa-chart-pie"></i>
                      SIMPLE & SECURE HOME LOANS
                    </p>
                    <h2
                      className="slider-one__title col-lg-12"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      Securing Your <br />
                      Financial Future
                    </h2>
                    <p
                      className="slider-one__text col-lg-6"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      Secure the best loan rates for you & your family now!
                    </p>
                    <div
                      className="slider-one__btns col-lg-12 sm"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        className="col-md-6"
                        style={{ marginRight: "10px" }}
                      >
                        {/* <Link
                          to="/forms"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            BUY A NEW HOME
                          </b>
                        </Link> */}
                        <Link to="/forms">
                          <Button
                            variant="outline-primary"
                            className="rounded-pill button2"
                            size="lg"
                          >
                            <div
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              BUY A NEW HOME
                            </div>
                          </Button>
                        </Link>
                      </span>
                      <span className="col-md-6" style={{ marginLeft: "10px" }}>
                        {/* <Link
                          to="/refinance"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            REFINANCE MY EXISTING LOAN
                          </b>
                        </Link> */}
                        <Link to="/refinance">
                          <Button
                            variant="outline-primary"
                            className="rounded-pill button2"
                            size="lg"
                          >
                            <div
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              REFINANCE MY EXISTING LOAN
                            </div>
                          </Button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="item">
            <div className="slider-one__item">
              {/* <div className="slider-one__lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
              <div
                className="slider-one__image"
                style={{ backgroundImage: `url(${BGImg})` }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 ">
                    <p
                      className="slider-one__tagline col-lg-12"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <i className="fa fa-chart-pie"></i>
                      EXPERIENCED ADVISORS AT THE READY
                    </p>
                    <h2
                      className="slider-one__title col-lg-12"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      Speak To Our Brokers In Person
                    </h2>
                    <p
                      className="slider-one__text col-lg-6"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      Book a free consultation!
                    </p>
                    <div
                      className="slider-one__btns col-lg-12"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        className="col-md-6"
                        style={{ marginRight: "10px" }}
                      >
                        {/* <Link
                          to="/forms"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            BUY A NEW HOME
                          </b>
                        </Link> */}
                        <Link to="/face-to-face-meeting">
                          <Button
                            variant="outline-primary"
                            className="rounded-pill button2"
                            size="lg"
                          >
                            <div
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              MAKE AN APPOINTMENT
                            </div>
                          </Button>
                        </Link>
                      </span>
                      {/* <span className="col-md-6" style={{ marginLeft: "10px" }}>
                        <Link
                          to="/refinance"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            REFINANCE MY EXISTING LOAN
                          </b>
                        </Link>
                        <Button
                          variant="outline-primary"
                          className="rounded-pill button2"
                          size="lg">
                          <div
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}>
                            REFINANCE MY EXISTING LOAN
                          </div>
                        </Button>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
         {/* <SwiperSlide className="item">
            <div className="slider-one__item">
              {/* <div className="slider-one__lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
          {/*
              <div
                className="slider-one__image"
                style={{ backgroundImage: `url(${BGImg2})` }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 ">
                    <p
                      className="slider-one__tagline col-lg-6"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                        float: "right",
                      }}
                    >
                      <i className="fa fa-chart-pie"></i>
                      INFORMATION JUST CLICKS AWAY
                    </p>
                    <h2
                      className="slider-one__title col-lg-12"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                        float: "right",
                      }}
                    >
                      Perform Your Own Research
                    </h2>
                    <p
                      className="slider-one__text col-lg-6"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                        float: "right",
                      }}
                    >
                      Equip yourself with knowledge of the loan process &
                      products!
                    </p>
                    <div
                      className="slider-one__btns col-lg-12"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        className="col-md-6"
                        style={{ marginRight: "10px", float: "right" }}
                      >
                        {/* <Link
                          to="/forms"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            BUY A NEW HOME
                          </b>
                        </Link> */}
                    {/* 
                        <Button
                          variant="outline-primary"
                          className="rounded-pill button2"
                          size="lg"
                        >
                          <div
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            CHECK OUT OUR BLOG
                          </div>
                        </Button>
                      </span>
                      {/* <span className="col-md-6" style={{ marginLeft: "10px" }}>
                        <Link
                          to="/refinance"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            REFINANCE MY EXISTING LOAN
                          </b>
                        </Link>
                        <Button
                          variant="outline-primary"
                          className="rounded-pill button2"
                          size="lg">
                          <div
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}>
                            REFINANCE MY EXISTING LOAN
                          </div>
                        </Button>
                      </span> */}
                 {/*}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
          <SwiperSlide className="item">
            <div className="slider-one__item">
              {/* <div className="slider-one__lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
              <div
                className="slider-one__image"
                style={{ backgroundImage: `url(${BGImg3})` }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 ">
                    <p
                      className="slider-one__tagline col-lg-6"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <i className="fa fa-chart-pie"></i>
                      PROPERTY MARKET UPDATES
                    </p>
                    <h2
                      className="slider-one__title col-lg-12"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      Stay Informed 
                      <br />
                      Stay Current
                    </h2>
                    <p
                      className="slider-one__text col-lg-12"
                      style={{
                        fontFamily: "Montserrat",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      Subscribe to our complimentary newsletters and
                      join our events!
                    </p>
                    <div
                      className="slider-one__btns col-lg-12"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        className="col-md-6"
                        style={{ marginRight: "10px" }}
                      >
                        {/* <Link
                          to="/forms"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            BUY A NEW HOME
                          </b>
                        </Link> */}
                        <Button
                          variant="outline-primary"
                          className="rounded-pill button2"
                          size="lg"
                        >
                          <div
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            SIGN ME UP
                          </div>
                        </Button>
                      </span>
                      {/* <span className="col-md-6" style={{ marginLeft: "10px" }}>
                        <Link
                          to="/refinance"
                          className="thm-btn thm-btn--dark-hover rounded-pill col-md-3">
                          <b style={{ fontFamily: "Montserrat" }}>
                            REFINANCE MY EXISTING LOAN
                          </b>
                        </Link>
                        <Button
                          variant="outline-primary"
                          className="rounded-pill button2"
                          size="lg">
                          <div
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}>
                            REFINANCE MY EXISTING LOAN
                          </div>
                        </Button>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="slider-one__carousel__btn">
          <button className="slider-one__carousel__btn__left">
            <i className="fa fa-angle-left"></i>
          </button>
          <button className="slider-one__carousel__btn__right">
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
        {/* <div
            className="slider-one__box wow fadeInRight"
            data-wow-duration="1500ms" >
            <div className="slider-one__box__icon">
                <i className="icon-successful"></i>
            </div>
            <div className="slider-one__box__content">
                <p className="slider-one__box__tagline">Checkout our</p>
                <h3 className="slider-one__box__title">88% Success Rates</h3>
            </div>
            </div> */}
      </section>
    </>
  );
}

export default Firstslider;
